import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { AppConsumer } from 'providers/app-provider';

// Svgs
import SoldoutCar from 'components/svgs/icons/soldout-car';

import Search from 'models/search';
import { Location } from 'models/locations';
import Quote from 'models/quote';
import Event from 'models/event';
import Venue from 'models/venue';
import Hub from 'models/hub';
import Brand from 'models/brand';

import { usesLargeFormatLocationDetails } from 'lib/common/search-helpers';

const propTypes = {
  app: PropTypes.string.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  currentSearch: PropTypes.instanceOf(Search),
  previousSearch: PropTypes.instanceOf(Search),
  displayMap: PropTypes.bool,
  displayModal: PropTypes.bool,
  showSearchDeepLinkBanner: PropTypes.bool,
  selectedQuote: PropTypes.instanceOf(Quote),
  selectedLocation: PropTypes.instanceOf(Location),
  venue: PropTypes.instanceOf(Venue),
  hub: PropTypes.instanceOf(Hub),
  event: PropTypes.instanceOf(Event),
  mounted: PropTypes.bool,
};

const defaultProps = {
  currentSearch: null,
  previousSearch: null,
  displayMap: false,
  displayModal: false,
  showSearchDeepLinkBanner: false,
  selectedQuote: null,
  selectedLocation: null,
  venue: null,
  hub: null,
  event: null,
  mounted: false,
};

export class CheckoutButton extends Component {
  constructor(props) {
    super(props);

    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll(e) {
    if (this.props.displayMap) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  hideCheckoutButton() {
    const {
      app,
      currentSearch,
      displayModal,
      previousSearch,
      showSearchDeepLinkBanner,
      selectedQuote,
      selectedLocation,
    } = this.props;
    const { isUnavailable = false } = selectedLocation || {};
    const loading = currentSearch.isDirty(previousSearch);

    return app !== 'Search' || loading || displayModal || !selectedQuote || isUnavailable || showSearchDeepLinkBanner || usesLargeFormatLocationDetails(this.props);
  }

  render() {
    if (!this.props.mounted) { return null; }
    const {
      currentSearch,
      selectedQuote,
      selectedLocation,
      venue,
      event,
      hub,
      brand,
    } = this.props;
    if (this.hideCheckoutButton()) { return null; }

    const checkoutButtonClasses = cx({
      'checkout-button': true,
      'not-available': !selectedQuote,
      'btn-bp': brand.isBestParking,
    });

    if (selectedQuote) {
      return (
        <div className="location-selected" onTouchMove={this.handleScroll} id="checkout-button" key="checkout-button">
          {selectedQuote.isTransient ? (
            <a
             href={`/checkout?quote_id=${selectedQuote.id}`}
             className={checkoutButtonClasses}
            >
             BOOK NOW
            </a>
          ) : (
          <Link
            to={{
              pathname: '/reserve',
              search: `?quote_id=${selectedQuote.id}`,
              state: {
                app: { name: 'Checkout' },
                search: {
                  currentSearch: currentSearch.toJSON(),
                  locationId: selectedLocation.id,
                  quoteId: selectedQuote.id,
                  selectedLocation: selectedLocation.toJSON(),
                  selectedQuote: selectedQuote.toJSON(),
                  event: event && event.toJSON(),
                  venue: venue && venue.toJSON(),
                  hub: hub && hub.toJSON(),
                },
              },
            }}
            className={checkoutButtonClasses}
          >
            BOOK NOW
          </Link>
          )}
        </div>
      );
    }

    return (
      <div className="location-selected" onTouchMove={this.handleScroll} id="checkout-button" key="checkout-button">
        <div className={checkoutButtonClasses}>
          <span className="isvg loaded">
            <SoldoutCar />
          </span>
          <span className="btn-text">Not Available</span>
        </div>
      </div>
    );
  }
}


CheckoutButton.propTypes = propTypes;
CheckoutButton.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => (
  bindActionCreators({}, dispatch)
);

const mapStateToProps = (state) => {
  const {
    currentSearch,
    previousSearch,
    displayModal,
    displayMap,
    showSearchDeepLinkInterstitial,
    selectedQuote,
    selectedLocation,
    venue,
    event,
    hub,
  } = state.search;

  const {
    name: app,
  } = state.app;

  const {
    brand,
  } = state.brand;

  return {
    currentSearch,
    previousSearch,
    selectedQuote,
    selectedLocation,
    displayModal,
    displayMap,
    showSearchDeepLinkInterstitial,
    app,
    brand,
    venue,
    event,
    hub,
  };
};

const CheckoutButtonWrapper = props => (
  <AppConsumer><CheckoutButton {...props} /></AppConsumer>
);

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutButtonWrapper);
